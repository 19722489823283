import {NavigationService} from '../services/NavigationService';
import {StyleSettingsService} from '../services/StyleSettingsService';
import {CheckoutNavigationService} from '@wix/wixstores-client-storefront-sdk/dist/es/src/services/CheckoutNavigationService/CheckoutNavigationService';
import {CartService} from '../services/CartService';
import {StoreMetaDataService} from '../services/StoreMetaDataService';
import {ORIGIN, OriginTypes, PAYPAL_METHOD_NAME, ShippingMethodType} from '../../components/cart/constants';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {clickOnCheckoutSfParams, clickOnCheckoutWithEWalletSfParams} from '@wix/bi-logger-ec-sf';
import {OrderService} from '../services/OrderService';
import {WithResultObservation} from '../../hooks/useFunctionResultObservation.worker';
import {StartFastFlowUrlResponse} from '@wix/wixstores-client-storefront-sdk/dist/es/src/apis/CheckoutNavigationApi/constants';
import {ModalManagerService} from '../services/ModalManagerService';
import {ModalType} from '@wix/wixstores-client-storefront-sdk/dist/es/src/services/CheckoutNavigationService/constants';
import {MinimumOrderAmountService} from '../services/MinimumOrderAmountService';

export type StartFastFlowCheckoutResponse = {
  wasServerResponseSuccessful: boolean;
  startFastFlowUrlResponse?: StartFastFlowUrlResponse;
};

export class NavigationStore {
  private readonly navigationService: NavigationService;
  private readonly styleSettingsService: StyleSettingsService;
  private readonly checkoutNavigationService: CheckoutNavigationService;
  private readonly cartService: CartService;
  private readonly orderService: OrderService;
  private readonly storeMetaDataService: StoreMetaDataService;
  private readonly modalManagerService: ModalManagerService;
  private readonly minimumOrderAmountService: MinimumOrderAmountService;

  constructor(
    private readonly siteStore: SiteStore,
    {
      navigationService,
      styleSettingsService,
      checkoutNavigationService,
      cartService,
      storeMetaDataService,
      orderService,
      modalManagerService,
      minimumOrderAmountService,
    }: {
      navigationService: NavigationService;
      styleSettingsService: StyleSettingsService;
      checkoutNavigationService: CheckoutNavigationService;
      cartService: CartService;
      storeMetaDataService: StoreMetaDataService;
      orderService: OrderService;
      modalManagerService: ModalManagerService;
      minimumOrderAmountService: MinimumOrderAmountService;
    },
    private readonly withResultObservation: WithResultObservation
  ) {
    this.navigationService = navigationService;
    this.styleSettingsService = styleSettingsService;
    this.cartService = cartService;
    this.orderService = orderService;
    this.checkoutNavigationService = checkoutNavigationService;
    this.storeMetaDataService = storeMetaDataService;
    this.modalManagerService = modalManagerService;
    this.minimumOrderAmountService = minimumOrderAmountService;
  }

  private readonly navigateToCheckoutInternal = async ({
    accessibilityEnabled,
    isFastFlow,
    cashierPaymentId,
  }: {
    accessibilityEnabled: boolean;
    isFastFlow: boolean;
    cashierPaymentId?: string;
  }): Promise<StartFastFlowCheckoutResponse | void> => {
    this.cartService.trackInitiateCheckout();

    const {hasCreatedPaymentMethods, activePaymentMethods, canStoreShip, isPremium} =
      await this.storeMetaDataService.get();

    const isPickupFlow = this.orderService.isPickup;

    const {canCheckout, modalType} = this.checkoutNavigationService.checkIsAllowedToCheckout({
      areAllItemsDigital: this.cartService.isDigitalCart,
      isPremium,
      canStoreShip,
      hasCreatedPaymentMethods,
      canShipToDestination: this.orderService.canShip || this.cartService.isFullAddressRequired,
    });

    const options = this.cartService.cart.shippingRuleInfo?.shippingRule?.options || [];

    const selectedShippingRuleOption = options.find(
      (option) => option.id === this.cartService.cart.selectedShippingOption.id
    );

    const existingShippingMethodType = isPickupFlow ? ShippingMethodType.PICKUP : ShippingMethodType.SHIPPING;
    const shippingMethodType = this.cartService.isDigitalCart ? ShippingMethodType.NONE : existingShippingMethodType;

    const BIData: clickOnCheckoutWithEWalletSfParams & clickOnCheckoutSfParams = {
      origin: ORIGIN,
      cartId: this.cartService.cart.cartId,
      itemsCount: this.cartService.itemsCount,
      cartType: this.cartService.cartType,
      productsList: JSON.stringify(
        this.cartService.cart.items.map((item) => ({id: item.product.id, quantity: item.quantity}))
      ),
      num_of_paymet_providers: activePaymentMethods.length,
      is_with_ewallet_payment: await this.storeMetaDataService.hasEWalletPaymentMethods(),
      is_with_offline_payment: await this.storeMetaDataService.hasOfflinePaymentMethods(),
      paymet_providers: (await this.storeMetaDataService.getPaymentMethodsNames()).join(','),
      orig_shipping_method: selectedShippingRuleOption?.title,
      shippingMethodType,
    };

    if (!canCheckout) {
      const response = await this.checkoutNavigationService.openModalByType(
        modalType,
        this.styleSettingsService.isEditorX,
        this.cartService.cart
      );

      const shouldProceedToCheckout = modalType === ModalType.UpgradeToPremium && response?.proceed;
      if (!shouldProceedToCheckout) {
        return;
      }
    }

    const deviceType = this.siteStore.isDesktop() ? 'desktop' : 'mobile';

    if (isFastFlow) {
      //eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.siteStore.biLogger.clickOnCheckoutWithEWalletSf({type: PAYPAL_METHOD_NAME, ...BIData});
      return this.navigationService.startFastFlowCheckout({
        cartId: this.cartService.cart.cartId,
        cashierPaymentId,
        isPickupFlow,
        a11y: accessibilityEnabled,
        locale: this.siteStore.locale,
        deviceType,
        withTax: this.styleSettingsService.shouldShowTax,
        withShipping: this.styleSettingsService.shouldShowShipping,
        shouldOpenCheckoutInViewer: await this.checkoutNavigationService.isEligibleForCheckoutInViewer(),
        originType: OriginTypes.AddToCart,
      });
    }

    //eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.siteStore.biLogger.clickOnCheckoutSf(BIData);

    return this.checkoutNavigationService.navigateToCheckout({
      a11y: accessibilityEnabled,
      cartId: this.cartService.cart.cartId,
      isFastFlow: false,
      locale: this.siteStore.locale,
      deviceType,
      originType: OriginTypes.AddToCart,
      isPickupOnly: isPickupFlow,
      siteBaseUrl: this.siteStore.location.baseUrl,
      ...(this.cartService.checkoutId ? {checkoutId: this.cartService.checkoutId} : {}),
    });
  };

  private readonly navigateToCheckout = async ({
    accessibilityEnabled,
    isFastFlow,
  }: {
    accessibilityEnabled: boolean;
    isFastFlow?: boolean;
  }) => {
    this.navigationService.isNavigationToCheckoutInProcess = true;
    await this.cartService.createCheckout();
    await this.navigateToCheckoutInternal({accessibilityEnabled, isFastFlow});
    this.navigationService.isNavigationToCheckoutInProcess = false;
  };

  private readonly startFastFlowCheckout = async ({
    accessibilityEnabled,
    cashierPaymentId,
  }: {
    accessibilityEnabled: boolean;
    cashierPaymentId: string;
  }): Promise<StartFastFlowCheckoutResponse> => {
    this.navigationService.isNavigationToCheckoutInProcess = true;
    const response = await this.navigateToCheckoutInternal({
      accessibilityEnabled,
      isFastFlow: true,
      cashierPaymentId,
    });
    this.navigationService.isNavigationToCheckoutInProcess = false;

    return response as StartFastFlowCheckoutResponse;
  };

  private readonly openPaypalError = (paypalError: string): Promise<any> => {
    return this.modalManagerService.modalManger.openPaypalError({message: encodeURIComponent(paypalError)});
  };

  private get isCheckoutButtonDisabled() {
    return (
      this.navigationService.isNavigationToCheckoutInProcess ||
      !this.cartService.areAllItemsInStock ||
      this.minimumOrderAmountService.shouldDisableCheckout
    );
  }

  public async toProps() {
    return {
      isCheckoutButtonDisabled: this.isCheckoutButtonDisabled,
      locale: this.siteStore.locale,
      openPaypalError: this.openPaypalError,
      ...this.withResultObservation({
        startFastFlowCheckout: this.startFastFlowCheckout,
      }),
      continueShopping: this.navigationService.continueShopping,
      continueShoppingHref: await this.navigationService.getContinueShoppingHref(),
      navigateToProduct: this.navigationService.navigateToProduct,
      shouldRenderContinueShopping: this.styleSettingsService.shouldRenderContinueShopping,
      navigateToCheckout: this.navigateToCheckout,
    };
  }
}
