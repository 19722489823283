export const SPECS = {
  CashierExpressNavigateToThankYouPageWhen1ShippingMethod:
    'specs.stores.CashierExpressNavigateToThankYouPageWhen1ShippingMethod',
  MinimumOrderStorefront: 'specs.stores.MinimumOrderStorefront',
  CartTooltipWithoutNumber: 'specs.stores.CartTooltipWithoutNumber',
  PaypalUpdateShippingAndBilling: 'specs.stores.PaypalUpdateShippingAndBilling',
  FastFlowCustomField: 'specs.stores.FastFlowCustomField',
  SeparatePaymentsStyleCart: 'specs.stores.SeparatePaymentsStyleCart',
  EXPRESS_CASHIER_BI_FIX: 'specs.stores.ExpressCashierBiFix',
  UseCommonProductLineItem: 'specs.stores.UseCommonProductLineItem',
  UseNewSslLogoCart: 'specs.stores.UseNewSslLogoCart',
  CreateCheckoutFromCart: 'specs.stores.CreateCheckoutFromCart',
  UseShowCouponStyleParam: 'specs.stores.UseShowCouponStyleParam',
  CartApiFastFlowWithCreateCheckout: 'specs.stores.CartApiFastFlowWithCreateCheckout',
} as const;
